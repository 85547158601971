<template>
  <div class="video-default">
    <!-- 视频容器 -->
    <div class="video-wrap" @scroll="scrollEvent"  >
      <!-- 视频组件 -->
      <videoView 
      :videoInfoId='videoInfo.id'
      :isLivedDefault='isLive'
      :isUmLiveDefault='isUmLive'
      :isCollcetDefault='isCollcet'
      :butHandleProps='butHandle' 
      :videoSrc='videoInfo.video_src' 
      :videoNameDefault='videoInfo.name'
      />

      <!-- 视频数据及评论  -->
      <div class="video-data">
        <div class="video-info">
          <div class="name-wrap">
            <div class="name">
              {{ videoInfo.name }}
            </div>
          </div>
          <div class="about-data">
            <div class="date">{{ videoInfo.date }}</div>
            <div class="time">
              <i class="iconfont icon-shijian"></i>
              {{ videoInfo.time }}
            </div>
            <div class="read_num">
              <i class="iconfont icon-rentouliangrense"></i>
              {{ videoInfo.read_num }}
            </div>
            <div class="cate">{{ videoInfo.cate }}</div>
          </div>
          <div class="position">
            <div
              :class="isLive === 1 ? 'live active-live' : 'live'"
              @click="butHandle('isLive', 1)"
            >
              <i class="iconfont icon-dianzan_kuai"></i>&nbsp;
              {{ videoInfo.live_num }}
            </div>
            <div
              :class="isUmLive === 1 ? 'live active-live' : 'live'"
              @click="butHandle('isUmLive', 1)"
            >
              <i class="iconfont icon-dislike-fill"></i>&nbsp;
              {{ videoInfo.un_live_num }}
            </div>
            <div
              :class="isCollcet === 1 ? 'live active-live' : 'live'"
              @click="butHandle('isCollcet', 1)"
            >
              <i class="iconfont icon-shoucang"></i>&nbsp;
              {{ videoInfo.collect_num }}
            </div>
            <div
              :class="isShare === 1 ? 'live active-live' : 'live'"
              @click="butHandle('isShare', 1)"
            >
              <i class="iconfont icon-zhuanfa"></i>&nbsp;
              {{ videoInfo.share_num }}
            </div>
          </div>
        </div>
        <!-- 评论 -->
        <comment
          :updateComment="getVideoComment"
          :sendComment="sendVideoComment"
          :moment="moment"
        />
      </div>
    </div>
    <!-- 右侧视频推荐 -->
    <div class="recommented">
      <div class="title">接下来播放</div>
      <div class="viddeo-list">
        <div
          class="video"
          @click="toVideoDefault(item.id)"
          @mouseover="showMask(item.id)"
          @mouseout="hideMask"
          v-for="item in recommendList"
          :key="item.id"
        >
          <div class="img-wrap">
            <div :class="mouseId === item.id ? 'mask' : 'mask hide_mask'">
              <div class="play">
                <i class="iconfont icon-shipin-copy"></i>
              </div>
              <div class="foolter-data">
                <div class="read_num">
                  <i class="iconfont icon-rentouliangrense"></i>
                  {{ item.read_num }}
                </div>
                <div class="video-time">
                  <i class="iconfont icon-shijian"></i>
                  {{ item.time }}
                </div>
              </div>
            </div>
            <img class="start" :src="item.img" alt="" />
          </div>
          <div class="datas">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="about">
              <div class="cate">推荐</div>
              <div class="date">{{ item.date | getDateNum }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import comment from "../../../components/comment.vue";
import formatDate from "../../../utils/formatDate";
import myStore from "../../../utils/localStore";
import videoView from '../../../components/video-view.vue'

export default {
  name: "vidoeDefault",
  props: ["videoId"],
  components: { comment, videoView },
  data() {
    return {
      userinfo: myStore.getStore("userinfo"),
      isLive: 0, // 喜欢 
      isUmLive:0, // 不喜欢
      isCollcet: 0, // 是否收藏
      isShare: 0, // 是否分享

      videoInfo: {}, // 视频数据
      query: {
        pagesize: 10,
        pagenum: 1,
        total: 0,
        maxpage: 5,
      }, // 评论查询数据
      moment: [], // 评论数据
      recommendList: [], // 视频推荐列表

      mouseId: null, // 经过的id
    };
  },
  beforeMount() {
    // 记录视频id
    this.$store.commit('video/SET_VIDEO_ID', this.videoId)
    this.getVideoInfo();
    this.recommendVideo();
  },
  mounted() {
    // window.addEventListener('scroll', this.scrollEvent);
  },
  beforeDestroy() {
    this.sendOperation()
    // window.removeEventListener('scroll', this.scrollEvent)
  },
  filters: {
    getDateNum(date) {
      let newDate = formatDate.format2(date);
      return newDate;
    },
  },
  methods: {
    // 获取视频数据
    async getVideoInfo() {
      let url = ''
      if (this.userinfo['id']){
        url = `video_default/?id=${this.videoId}&user=${this.userinfo['id']}`
      }else{
        url =  `video_default/?id=${this.videoId}`
      }

      const { data: res } = await this.$http.get(url);
      if (res.code !== "200") return;
      this.videoInfo = res.data;
      // 预处理
      // 喜欢/不喜欢
      this.videoInfo.date = formatDate.format2(this.videoInfo.date);
      if(this.videoInfo.operation.live){
          if(this.videoInfo.operation.live==1){
              this.isLive = 1
              this.isUmLive = 0
          }else if(this.videoInfo.operation.live == -1){
              this.isLive = 0
              this.isUmLive = 1
          }
      }
      // 收藏
      if(this.videoInfo.operation.collect){
        this.isCollcet = 1
      }

      // 分享 
      if(this.videoInfo.operation.share){
        this.isShare = 1
      }
    },

    // 获取评论数据
    async getVideoComment() {
      // 判断是否为最后
      if (this.query.maxpage < this.query.pagenum) return;

      let { data: res } = await this.$http.get(
        `video_comment/?id=${this.videoId}&pagesize=${this.query.pagesize}&pagenum=${this.query.pagenum}`
      );
      if (res.code !== "200") return;
      // 格式化
      res.data.list.forEach((v) => {
        v.datetime = formatDate.format1(v.datetime);
      });
      this.moment = [...this.moment, ...res.data.list];
      this.query.total = res.data.total;
      this.query.maxpage = res.data.maxpage;

      //  为下一次刷新准备
      this.query.pagenum += 1;
    },

    // 接收，发送评论数据
    async sendVideoComment(form, backId) {
      let form_data = {
        id: this.videoInfo.id,
        user_id: this.userinfo.id,
        data: form,
      };
      if (backId) {
        form_data["parent"] = backId;
      }

      let { data: res } = await this.$http.post("video_comment/", form_data);
      if(res.code!=='200'){
        // token过期
         this.$notify({
          title: '登录过期',
          message: '请退出并重新登录'
        });
        return
      }
      // if (res.code !== "200") return;
      // 向前添加数据
      if (backId) {
        // 回复露出
        this.moment.forEach((v) => {
          if (v.id === backId) {
            v.pl_data.unshift({ user: this.userinfo.username, data: form });
          }
        });
      } else {
        // 回复项目
        res.data.datetime = formatDate.format1(res.data.datetime);
        this.moment.unshift(res.data);
      }
    },

    // 获取视频推荐
    async recommendVideo() {
      const { data: res } = await this.$http.get(
        `video_recommend/?id=${this.videoId}&user=${this.userinfo.id}`
      );
      if (res.code !== "200") return;
      this.recommendList = res.data;
    },

    // 点击视频，进入视频详情d
    toVideoDefault(id) {
      console.log(id);
      this.$router.replace({
        path: "/home/video_list/videoDefault",
        query: { id },
      });
      // 刷新
      this.sendOperation() // 记录数据
      this.$router.go(0)
    },

    // 视频模态框显示
    showMask(id) {
      this.mouseId = id;
    },
    // 隐藏模态框
    hideMask() {
      this.mouseId = null;
    },

    //监听滚动
   scrollEvent () {
     console.log('aaaa');
  },

    // 点击按钮的
    butHandle(types, num) {
      // 判断是否登录
      if (!this.userinfo.id) return alert('还没有登录')
      if (this[types] === 1 || this[types] === -1) {
        this[types] = 0;
      } else {
        this[types] = num;
      }
      // 页面渲染
      switch (types) {
        case 'isLive':
          if(this.isLive){
            this.videoInfo.live_num+=num
            if(this.isUmLive == 1){
                 this.videoInfo.un_live_num-=num
                 this.isUmLive = 0
            }
           
          }else{
            this.videoInfo.live_num-=num
          }
          break;
        case 'isUmLive':
           if(this.isUmLive){
            this.videoInfo.un_live_num+=num
             if(this.isLive == 1){
                 this.videoInfo.live_num-=num
                 this.isLive = 0
            }
          }else{
            this.videoInfo.un_live_num-=num
          }
          break
        case 'isCollcet':
          if(this.isCollcet == 1){
            this.videoInfo.collect_num += 1
          }else{
            this.videoInfo.collect_num -= 1
          }
          break
        case 'isShare':
          if(this.isShare == 1){
            this.videoInfo.share_num += 1
          }else{
             this.videoInfo.share_num -= 1
          }
          break
        default:
          break;
      }

      // 
    },

    // 页面关闭 发送点赞数据
   async sendOperation(){
      let data = {
        id:this.videoId,
        live_num:this.isLive,
        un_live_num:this.isUmLive,
        collect_num:this.isCollcet,
        share_num:this.isShare
      }
      const {data:res} = await this.$http.post('video_operation/', data)
      if(res.code!=='200') return
    }
  },
};
</script>

<style lang='less' scoped>
// 隐藏mask
.hide_mask {
  display: none;
}

.video-default {
  padding: 20px 10px;
}

.video-wrap {
  float: left;
  width: 75%;
  height: 100%;
  padding-right: 10px;
 // overflow: hidden;  
 // overflow-y: auto;

 

  // 视频数据
  .video-data {
    width: 100%;
    padding: 15px 0;
    .video-info {
      width: 100%;
      height: 120px;
      // background: #fff;

      .name-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 20px;
          color: rgb(39, 39, 39);
        }
      }

      .about-data {
        padding: 5px;
        font-size: 13px;
        height: 20px;
        color: rgb(143, 143, 143);
        .date {
          float: left;
        }
        .time {
          float: left;
          margin: 0 15px;
        }
        .read_num {
          float: left;
        }
        .cate {
          float: right;
          font-weight: 700;
        }
      }
      .position {
        padding: 15px;
        height: 40px;
        color: rgb(136, 136, 136);
        .live {
          float: left;
          margin-right: 25px;
          line-height: 32px;
          font-size: 14px;
          i {
            font-size: 24px;
          }
        }
        .live:hover {
          cursor: pointer;
          color: rgb(48, 48, 48);
        }

        .active-live {
          animation: ChangeLive 1s linear;
          color: #3d5aeb;
        }

        .unactive {
          animation: ChangeLive 1s linear reverse;
          color: rgb(136, 136, 136);
        }
      }
    }
  }
}

// 点击按钮的动画
// 颜色
@keyframes ChangeLive {
  0% {
    color: rgb(48, 48, 48);
  }
  50% {
    color: #e30fff;
  }
  100% {
    color: #3d5aeb;
  }
}

// 视频推荐
.recommented {
  float: left;
  width: 220px;

  .title {
    text-align: center;
    color: rgb(128, 128, 128);
  }

  .viddeo-list {
    width: 220px;

    .video {
      width: 200px;
      height: 150px;
      margin: 20px 15px;

      .img-wrap {
        height: 115px;
        width: 100%;
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        // 模态框
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(1, 1, 1, 0.4);

          .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;

            i {
              font-size: 28px !important;
            }
          }
          .foolter-data {
            position: absolute;
            bottom: 0;
            height: 24px;
            line-height: 24px;
            width: 100%;
            padding: 0 5px;
            display: flex;
            justify-content: space-between;
            color: #fff;
            font-size: 10px;
            .read_num {
              i {
                font-size: 12px !important;
              }
            }

            .video-time {
              i {
                font-size: 12px !important;
              }
            }
          }
        }
        .start{
          background: rgb(184, 184, 184);
        }
      }

      .datas {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 13px;
          height: 35px;
          width: 100%;
          padding-right: 10px;
        }
        .about {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: rgb(109, 109, 109);
          .cate {
          }
          .date {
          }
        }
      }
    }
  }
}
</style>