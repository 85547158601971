<template>
  <div :class="isFullScreen ? 'is-full-screen' : 'my-video-wrap'">
    <video ref="videoContent" class="my-video-view" autoplay>
      <source :src="videoSrcData" type="video/mp4" />
    </video>
    <div
      ref="video_mask"
      @mousemove="monitorMover"
      class="my-video-mask"
      onclick="FullScreen"
    >
      <!-- 视频名称信息 -->
      <div v-show="isShowMask" class="vide-name-data">
        <span>{{ videoName }}</span>
      </div>
      <!-- 视频弹幕 -->
      <div class="video-barrage" @click="changePlay">
        <videoAberrage v-show="bulletWordKey" :videoInfoId='videoInfoId'  />
      </div>
      <!-- 底部控件 -->
      <div v-show="isShowMask" class="video-footer">
        <!-- 进度条 -->
        <div class="video-time-len" ref="video_itme_len" @click="setVideoTime">
          <div class="now-video-len" :style="`width:${videoNow}%;`"></div>
        </div>
        <div class="main-opts">
          <!-- 点赞区 -->
          <div v-show="isFullScreen" class="three-live">
            <div
              @click="butHandle('isLive', 1)"
              :class="isLive ? 'check-color' : ''"
            >
              <i class="iconfont icon-dianzan_kuai"></i>
            </div>
            <div
              @click="butHandle('isUmLive', 1)"
              :class="isUmLive ? 'check-color' : ''"
            >
              <i class="iconfont icon-dislike-fill"></i>
            </div>
            <div
              @click="butHandle('isCollcet', 1)"
              :class="isCollcet ? 'check-color' : ''"
            >
              <i class="iconfont icon-shoucang"></i>
            </div>
          </div>

          <!-- 视频时长/开始/暂停 -->
          <div class="main-live">
            <div class="big-live">
              <i
                @click="changePlay"
                v-show="!isPlay"
                class="iconfont icon-kaishi"
              ></i>
              <i
                @click="changePlay"
                v-show="isPlay"
                class="iconfont icon-zanting1"
              ></i>
            </div>
            <div class="now-time">{{ videoNowTime }}/{{ videoAllTime }}</div>
          </div>
          <!-- 发弹幕 -->
          <div v-show="isFullScreen" class="input-live">
            <input
              v-model="bulletWordData"
              @input="showInput"
              @blur="hedeInput"
              @keyup.enter="sendBulletWord"
              type="text"
              placeholder="发条弹幕吧"
            />
            <div class="check-color check-co">
              <div :class="'show-color '+ checkTextColor"></div>
              <div class="color-list">
                <ul>
                  <li v-for="item in colorList" :key='item'>
                    <div @click="checkColorText(item)" :class="'color-itme ' + item"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div @click="sendBulletWord" class="but">发送</div>
          </div>
          <div class="rigth-live">
            <div
              :class="
                bulletWordKey ? 'live barrage check-color' : 'live barrage'
              "
              @click="bulletWordKey = !bulletWordKey"
            >
              弹幕
            </div>
            <div class="live pic">
              {{ test1[checkPicture].size }}
              <div class="picture">
                <ul>
                  <li
                    v-for="(p, index) in test1"
                    :key="index"
                    :class="checkPicture == index ? 'check-color' : ''"
                    @click="changePicture(index)"
                  >
                    {{ p.size }}&nbsp;{{ p.text }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="live spd">
              {{ test2[checkSpeed] }}
              <div class="speed">
                <ul>
                  <li
                    v-for="(v, index) in test2"
                    :key="index"
                    :class="checkSpeed == index ? 'check-color' : ''"
                    @click="changeSpeed(index)"
                  >
                    {{ v }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="live aud">
              <i
                v-show="!notUseAudio"
                @click="openAudio"
                class="iconfont icon-shengyin_shiti"
              ></i>
              <i
                v-show="notUseAudio"
                @click="openAudio"
                class="iconfont icon-jingyinmute31"
              ></i>
              <div class="audio_num">
                <el-slider
                  class="e_aud"
                  v-model="checkAudioNum"
                  @input="changeAudioNum"
                  vertical
                  height="200px"
                >
                </el-slider>
              </div>
            </div>
            <div class="live" @click="changeScreenSize">
              <i class="iconfont icon-quanping"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from "../utils/formatDate";
import videoAberrage from "./video-aberrage.vue";

export default {
  name: "video-view",
  props: [
    'videoInfoId',
    "videoSrc",
    "butHandleProps",
    "isLivedDefault",
    "isUmLiveDefault",
    "isCollcetDefault",
    "videoNameDefault",
  ],
  components: { videoAberrage },
  data() {
    return {
      videoName: "",
      videoSrcData: "#",
      videoElement: null, // 视频容器对象
      videoIsSuccess: false, // 获取视频成功
      isFullScreen: false, // 是否全屏播放
      isShowMask: true, // 显示操作栏
      closeMaskTimer: null, // 定时关闭操作栏--定时器
      isPlay: true,
      videoNow: 0, // 视频进度
      getVLenTimer: null, // 监听视频进度--定时器
      checkPicture: 0, // 选择的画质索引
      checkSpeed: 3, // 选择的倍速的索引
      notUseAudio: false, // 开启/关闭音频
      checkAudioNum: 50, // 视频的音量
      bulletWordKey: true, // 弹幕开关
      baberrageList:[], // 弹幕信息列表
      bulletWordData: "", // 弹幕输入框
      checkTextColor:'white', // 选择颜色的索引

      isLive: this.isLivedDefault, // 喜欢
      isUmLive: this.isUmLiveDefault, // 不喜欢
      isCollcet: this.isCollcetDefault, // 是否收藏
      videoTDataTimer: null, // 更新视频时间--定时器
      videoAllTime: "00:00:00",
      videoNowTime: "00:00:00",
      // test
      test1: [
        {
          size: "1080P",
          text: "超清",
          src: "http://120.24.182.152:5555/s/static/s/image_files/video_files/1643781007.6899054in2022-02-02-13-50-08is.mp4",
        },
        {
          size: "720P",
          text: "高清",
          src: "http://120.24.182.152:5555/s/static/s/image_files/video_files/1643781007.6899054in2022-02-02-13-50-08is.mp4",
        },
        {
          size: "480P",
          text: "清晰",
          src: "http://120.24.182.152:5555/s/static/s/image_files/video_files/1643781007.6899054in2022-02-02-13-50-08is.mp4",
        },
        {
          size: "360P",
          text: "超清",
          src: "http://120.24.182.152:5555/s/static/s/image_files/video_files/1643781007.6899054in2022-02-02-13-50-08is.mp4",
        },
      ],
      colorList:['white','red','green','blue','orange','pink','black'],
      test2: ["2.0倍", "1.5倍", "1.25倍", "1.0倍", "0.75倍", "0.5倍"],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 挂载视频DOM元素
      this.videoElement = this.$refs.videoContent;
      // 挂载视频加载成功
      this.videoElement.addEventListener("canplaythrough", () => {
        this.successGetVideo();
      });
    });
    // 开启监听视频进度定时器
    this.getVLenTimer = setInterval(() => {
      this.getVideoTime();
    }, 100);
    // 开启视频时间计数定时器
    this.videoTDataTimer = setInterval(() => {
      if (this.videoElement && this.videoIsSuccess) {
        this.videoAllTime = dateFormat.format5(
          this.videoElement.duration * 1000
        );
        this.videoNowTime = dateFormat.format5(
          this.videoElement.currentTime * 1000
        );
        // 弹幕更新
        this.$bus.$emit('updateBaberrage', this.videoElement.currentTime)
      }
    }, 1000);
    // 监听ESC键
    window.addEventListener("keyup", this.keyUpEscape);
  },
  beforeDestroy() {
    clearInterval(this.getVLenTimer);
    clearTimeout(this.closeMaskTimer);
    clearTimeout(this.videoTDataTimer);
    // 取消监听ESC
    window.removeEventListener("keyup", this.keyUpEscape);
  },
  methods: {
    // 点击开始/暂停
    changePlay() {
      // 防止关闭
      clearTimeout(this.closeMaskTimer);
      if (this.isPlay) {
        this.videoElement.pause();
        this.isPlay = false;
      } else {
        this.videoElement.play();
        this.isPlay = true;
      }
    },

    // 点击放大/缩小
    changeScreenSize() {
      if (this.isFullScreen) {
        this.exitFullscreen();
        this.isFullScreen = false;
      } else {
        this.FullScreen();
        this.isFullScreen = true;
      }
    },

    // 视频资源获取成功
    successGetVideo() {
      this.videoIsSuccess = true;
    },

    // 监听esc事件
    keyUpEscape(e) {
      if (e.code == "Escape" && this.isFullScreen) {
        clearTimeout(this.closeMaskTimer);
        this.isFullScreen = false;
        this.isShowMask = true;
      }
    },
    //进入全屏
    FullScreen() {
      // 占满全屏
      var ele = document.documentElement;
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen();
      }
    },
    //退出全屏
    exitFullscreen() {
      var de = document;
      try {
        if (de.exitFullscreen) {
          de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen();
        }
        clearTimeout(this.closeMaskTimer);
        this.isShowMask = true;
      } catch (e) {
        this.isShowMask = true;
      }
    },

    // 进入全屏 监测鼠标鼠标移动
    monitorMover() {
      if (!this.isFullScreen) return;
      this.isShowMask = true;
      clearTimeout(this.closeMaskTimer);
      this.closeMaskTimer = setTimeout(() => {
        this.isShowMask = false;
      }, 2000);
    },

    // 获取视频进度
    getVideoTime() {
      let now_len = this.videoElement.currentTime / this.videoElement.duration;
      now_len = (parseInt(now_len * 1000) / 1000) * 100;
      this.videoNow = now_len;
    },
    // 改变视频进度
    setVideoTime(e) {
      let len = this.$refs.video_itme_len.offsetWidth;
      let toLen = parseInt((e.offsetX / len) * 1000) / 1000;
      // 操作视频
      this.videoElement.currentTime = this.videoElement.duration * toLen;
    },

    // 选择颜色
    checkColorText(color){
      this.checkTextColor = color
    },

    // 选择画质
    changePicture(index) {
      this.checkPicture = index;
    },

    // 选择倍速
    changeSpeed(index) {
      // playbackRate  属性
      if (index == 0) {
        this.videoElement.playbackRate = 2;
      } else if (index == 1) {
        this.videoElement.playbackRate = 1.5;
      } else if (index == 2) {
        this.videoElement.playbackRate = 1.25;
      } else if (index == 3) {
        this.videoElement.playbackRate = 1;
      } else if (index == 4) {
        this.videoElement.playbackRate = 0.75;
      } else if (index == 5) {
        this.videoElement.playbackRate = 0.5;
      }
      this.checkSpeed = index;
    },

    // 开启/关闭音频
    openAudio() {
      // muted
      if (this.notUseAudio) {
        this.videoElement.muted = false;
        this.notUseAudio = false;
      } else {
        this.videoElement.muted = true;
        this.notUseAudio = true;
      }
    },

    // 调整音量
    changeAudioNum() {
      if (!this.videoElement) return;
      this.videoElement.volume = this.checkAudioNum / 100;
    },

    // 喜欢/不喜欢/收藏
    butHandle(types, num) {
      // 判断是否登录
      // if (!this.userinfo.id) return alert('还没有登录')
      if (this[types] === 1 || this[types] === -1) {
        this[types] = 0;
      } else {
        this[types] = num;
      }
      // 传递父组件
      switch (types) {
        case "isLive":
          if (this.isUmLive) {
            this.isUmLive = 0;
          }
          this.butHandleProps("isLive", 1);
          break;
        case "isUmLive":
          if (this.isLive) {
            this.isLive = 0;
          }
          this.butHandleProps("isUmLive", 1);
          break;
        case "isCollcet":
          this.butHandleProps("isCollcet", 1);
          break;
        default:
          break;
      }
    },

    // 弹幕消息/保存控制栏
    showInput() {
      if (this.bulletWordData.trim()) return;
      if (this.closeMaskTimer) {
        clearTimeout(this.closeMaskTimer);
      }
    },
    // 恢复控制了隐藏
    hedeInput() {
      this.monitorMover();
    },
    // 发弹幕
   async sendBulletWord() {
      // 校验
      if(!this.bulletWordData.trim()) return
      if(!this.videoIsSuccess) return
      // 视频时间(所在位置)
      let video_time = parseInt(this.videoElement.currentTime+3)
      // 所在视频段落
      let paragraph = parseInt(video_time / 15)+1
      let data = {
        id:this.videoInfoId,
        msg:this.bulletWordData,
        color:this.checkTextColor,
        video_time,
        paragraph
      }
      const {data:res} = await this.$http.post('video_baberrage/',data)
      if(res.code!='200') return
      this.bulletWordData = ''
      // 更新弹幕
      this.$bus.$emit('pushNewData', data)
    },
  },
  watch: {
    isLivedDefault: {
      handler(newValue, _) {
        this.isLive = newValue;
      },
    },
    isUmLiveDefault: {
      handler(newValue, _) {
        this.isUmLive = newValue;
      },
    },
    isCollcetDefault: {
      handler(newValue, _) {
        this.isCollcet = newValue;
      },
    },
    videoNameDefault: {
      handler(newValue, _) {
        this.videoName = newValue;
      },
    },
    videoSrc: {
      handler(newValue, _) {
        this.videoSrcData = newValue;
        if (this.videoElement) {
          this.videoElement.load();
        } else {
        }
      },
    },
  },
};
</script>

<style lang='less' scoped>
div {
  margin: 0;
  padding: 0;
  border: 0;
}

.lt_but {
  font-size: 14px;
}
.gt_but {
  font-size: 28px;
}

.my-video-wrap {
  position: relative;
  margin: 0 auto;
  width: 58vw;
  height: 58vh;
  background: #000;
}
/* 点击全屏的大小 */
.is-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;
  background: #000;
}

.my-video-view {
  width: 100%;
  height: 100%;
  z-index: 100;
}

/* 视频控件 */
.my-video-mask {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  font-size: 1.5rem;
}
/* 视频信息 */
.vide-name-data {
  padding-left: 20px;
  height: 3rem;
  line-height: 3rem;
  font-size: 2rem;
  color: #fff;
  background: linear-gradient(rgba(1, 1, 1, 0.8), rgba(0, 0, 0, 0.2));
}
// 视频弹幕
.video-barrage {
  width: 100%;
  height: calc(100% - 8rem);
}

// 视频控制栏
.video-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  // background-color: rgba(0, 0, 0, 0.4);
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(1, 1, 1, 0.8));
}

// 进度条
.video-time-len {
  position: relative;
  width: 100%;
  height: 6px;
  background: #f6f5f8;

  .now-video-len {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #3d5aeb;
  }
}

.main-opts {
  width: 100%;
  height: calc(3rem - 8px);
  display: flex;
  justify-content: space-around;
}

.live {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

// 喜欢/收藏
.three-live {
  width: 200px;
  height: 3.5rem;
  line-height: 3.5rem;
  color: #fff;
  display: flex;
  justify-content: space-around;

  i {
    font-size: 2.2rem !important;
    color: #fff;
  }
}

// 开始/暂停
.main-live {
  width: 240px;
  height: 3.5rem;
  line-height: 3.5rem;
  color: #fff;
  display: flex;
  justify-content: space-around;

  i {
    font-size: 2.4rem !important;
    color: #fff;
  }
}

.input-live {
  width: 300px;
  height: 3.5rem;
  padding: 11px;
  display: flex;

  input {
    padding-left: 8px;
    width: 74%;
    height: 100%;
    border-radius: 0.8rem 0 0 0.8rem;
    caret-color: #3d5aeb; // 修改 焦点光标
    outline-color: #3d5aeb; // 选中后框的颜色
    background: #f6f5f8;
    border: 1px solid rgb(201, 200, 200);
  }
  .check-color {
    width: 14%;
    height: 100%;
    background: #f6f5f8;
    padding: 8px;
    position: relative;
    .show-color {
      width: 100%;
      height: 100%;
    }
  }

  .but {
    width: 20%;
    font-size: 14px;
    padding: 0.5rem;
    text-align: center;
    background: #3d5aeb;
    color: #fff;
    border-radius: 0 0.8rem 0.8rem 0;
    cursor: pointer;
  }
}
// 选择颜色
.color-list {
  display: none;
  position: absolute;
  left: -10px;
  top: -290px;
  width: 60px;
  height: 290px;
  background-color: rgba(0, 0, 0, 0.4);
  ul {
    padding: 10px;
    li {
      padding: 10px;
      .color-itme {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.check-co:hover .color-list {
  display: block;
}

.rigth-live {
  width: 500px;
  height: 3.5rem;
  line-height: 3.5rem;
  color: #fff;
  display: flex;
  justify-content: space-around;

  i {
    font-size: 2.5rem !important;
    color: #fff;
  }
}

.live i {
  font-size: 2.2rem !important;
  color: #fff;
}
.barrage {
  width: 50px;
}

.letter-live i {
  font-size: 2.2rem !important;
  color: #fff;
}
.big-live i {
  font-size: 3rem !important;
  color: #fff;
}
.now-time {
  line-height: 3.5rem;
  font-size: 20px;
  color: #fff;
}

// 画质
.picture {
  display: none;
  position: absolute;
  left: -50px;
  top: -230px;
  width: 150px;
  height: 230px;
  background-color: rgba(0, 0, 0, 0.4);

  ul {
    li {
      font-size: 16px;
    }
  }
}
.pic:hover .picture {
  display: block;
}
// 倍速
.speed {
  display: none;
  position: absolute;
  left: -30px;
  top: -340px;
  width: 100px;
  height: 340px;
  background-color: rgba(0, 0, 0, 0.4);

  ul {
    li {
      font-size: 16px;
    }
  }
}
.spd:hover .speed {
  display: block;
}
// 音量
.audio_num {
  display: none;
  position: absolute;
  left: 0px;
  top: -250px;
  width: 50px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px 5px;
}
.aud:hover .audio_num,
.audio_num:hover .audio_num,
.e_aud:hover .audio_num {
  display: block;
}

.check-color {
  color: #3d5aeb;
  i {
    color: #3d5aeb;
  }
}

// 颜色列表
.white{
  background: rgb(255, 255, 255) !important;
}
.red{
  background: red;
}
.green{
  background: rgb(37, 218, 1) !important;
}
.blue{
  background:rgb(1, 88, 218) !important
}
.orange{
  background: rgb(255, 159, 15) !important;
}
.pink{
  background: pink;
}
.black{
  background: black;
}

</style>