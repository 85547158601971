<template>
  <div class="barrages-drop">
    <vue-baberrage
      :barrageList="barrageListShow"
      :maxWordCount="maxWordCount"
      :throttleGap="throttleGap"
      :loop="barrageLoop"
      :boxWidth="1600"
      :boxHeight="boxHeight"
      :messageHeight="messageHeight"
    >
    </vue-baberrage>
  </div>
</template>
<script>
import { MESSAGE_TYPE } from "vue-baberrage";

export default {
  name: "Barrages",
  props: ["videoAberrageShow","videoInfoId"],
  data() {
    return {
      msg: "马优晨",
      barrageIsShow: true,
      messageHeight: 20,
      boxHeight: 100,
      barrageLoop: false,
      maxWordCount: 2,
      throttleGap: 5000,
      barrageListShow: [],
      baberrageList: [],
      reomoveList: [], // 将要删除的弹幕索引
      paragraphNow:1 // 当前视频段落
    };
  },
  mounted() {
    this.getBaberrageList()
    this.$bus.$on("updateBaberrage", this.updateBaberrage);
    this.$bus.$on("pushNewData", this.pushNewData);
  },
  beforeDestroy() {
    this.$bus.$off("updateBaberrage");
    this.$bus.$off("pushNewData");
  },
  methods: {
    // 获取弹幕
   async getBaberrageList(paragraph=1) {
      this.paragraphNow = paragraph
      this.baberrageList = []
      const {data:res} = await this.$http.get(
        `video_baberrage/?id=${this.$store.state.video.playVidreId}&paragraph=${paragraph}`
        )
      if(res.code!=='200') return
      this.baberrageList = res.data
    },

    // 更新数据
   async updateBaberrage(videoTime) {
      this.reomoveList = [];
      let paragraphInt = parseInt(videoTime/15) + 1
      if(this.paragraphNow != paragraphInt){
        this.getBaberrageList(paragraphInt)
      }
      // console.log(this.baberrageList);
      if (!this.baberrageList) return;
      this.baberrageList.forEach((v, i) => {
        if (v.video_time >= videoTime && v.video_time < videoTime + 1) {
          this.barrageListShow.push({
            id: v.id,
            msg: v.msg,
            time: 6,
            type: MESSAGE_TYPE.NORMAL,
            barrageStyle: v.color,
          });
          // 加入删除列表中
          this.reomoveList.push(v.id);
        }
      });
      // 删除已展示弹幕
      // this.reomoveList.forEach(v=>{

      // })
      // console.log(this.reomoveList);
    },

    // 输入添加新数据
    pushNewData(data) {
      this.barrageListShow.push({
        id: data.id,
        msg: data.msg,
        time: 6,
        type: MESSAGE_TYPE.NORMAL,
        barrageStyle: data.color,
      });
    },
  },
};
</script>
<style lang="less">
.baberrage-stage {
  margin: 0 auto;
}

.barrages-drop {
  width: 100%;
  height: 100%;

  .baberrage-msg {
  }

  .white {
    .normal {
      color: rgb(255, 255, 255) !important;
      background: rgba(0, 0, 0, 0);
    }
  }

  .red {
    .normal {
      color: red !important;
      background: rgba(0, 0, 0, 0);
    }
  }

  .green {
    .normal {
      color: rgb(37, 218, 1) !important;
      background: rgba(0, 0, 0, 0);
    }
  }

  .blue {
    .normal {
      color: rgb(1, 88, 218) !important;
      background: rgba(0, 0, 0, 0);
    }
  }

  .orange {
    .normal {
      color: rgb(255, 159, 15) !important;
      background: rgba(0, 0, 0, 0);
    }
  }

  .pink {
    .normal {
      color: pink !important;
      background: rgba(0, 0, 0, 0);
    }
  }

  .black {
    .normal {
      color: black !important;
      background: rgba(0, 0, 0, 0);
    }
  }
}
</style>